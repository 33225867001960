import * as React from "react"
import Layout from "../components/layout"
import Services from "../components/services"
import Articles from "../components/articles"
import Helmet from 'react-helmet'

const CapabilitiesPage = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'capabilities-page whitemain'
    }}
/>
  <Layout pageTitle="Capabilities">
    <section id="intro">
      <div className="intro">
        <div className="left-col">
          <h2 className="reveal-textDIS"><span className="revealblue">Want to tap into the expertise of developer that’s been in operation since 2001?</span></h2>
          <p><span className="revealblack">Our additional capabilities can be any of the general requirements below such as designing, applications, animation, digital strategy and wireframe, web and social analytics, security, optimisation, website maintenance and support, domain name registration, email accounts, shared web hosting, dedicated or virtual private server and more.</span></p>
        </div>
        <div className="right-col">
          <div className="icons-row">
            <img className="picture" src={'/img/capabilities/wordpress.svg'} width="138" height="86" alt="Wordpress" />
            <img className="picture" src={'/img/capabilities/woocommerce.svg'} width="246" height="50" alt="Woocommerce" />
            <img className="picture" src={'/img/capabilities/magento.svg'} width="70" height="82" alt="Magento" />
          </div>
          <div className="icons-row">
            <img className="picture" src={'/img/capabilities/php.svg'} width="121" height="61" alt="Php" />
            <img className="picture" src={'/img/single/Gatsby.svg'} width="209" height="131" alt="Gatsby" />
            <img className="picture" src={'/img/capabilities/apple.svg'} width="67" height="77" alt="Apple" />
          </div>
          <div className="icons-row">
            <img className="picture" src={'/img/capabilities/js.svg'} width="81" height="81" alt="Javascript" />
            <img className="picture" src={'/img/capabilities/jquery.svg'} width="175" height="40" alt="jQuery" />
            <img className="picture" src={'/img/capabilities/shopify.svg'} width="182" height="52" alt="Shopify" />
          </div>

        </div>
      </div>
    </section>
    
<Services></Services>
<Articles></Articles>
</Layout>
  </>
  )
}

export default CapabilitiesPage


export const Head = () => (
  <>
    <title>Capabilities - Tobstar</title>
    <meta name="description" content="Our additional capabilities can be any of the general requirements below such as applications, web and social analytics, security, domain name registration, email accounts, shared web hosting, dedicated or virtual private server and more. See all our capabilities below to learn more." />
  </>
)