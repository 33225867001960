import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Services() {
  return (
    <section id="main">
<h3 className="h3home"><span className="reveal">Melbourne Web Design and Development</span></h3>
  <h1 className="h1home "><span className="reveal">We are a distinguished web design and web development company in Melbourne, forging partnerships with agencies and designers across Australia.</span></h1>

      <h2 id="specialising">
        <span className="whatarewedoing">What are we doing? </span>
        <span className="textReveal">
		  <AniLink cover to="/capabilities/web-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}  id="web-dev">Web Development </AniLink>
        </span>
        <span className="textReveal anim1">
		  <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} id="wordpress-dev">WordPress Development </AniLink>
        </span>
        <span className="textReveal anim2">
		  <AniLink cover to="/capabilities/ecommerce-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} id="ecommerce-dev">Ecommerce Development </AniLink>
        </span>
        <span className="textReveal anim3">
 		  <AniLink cover to="/capabilities/app-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} id="app-dev">Mobile Web App Development </AniLink>
       </span>
        <span className="textReveal anim4">
  		  <AniLink cover to="/capabilities/api-development-melbourne" bg="#00ccd4" direction="right" duration={0.76} id="api-dev">API Integration Development </AniLink>
       </span>
        <span className="textReveal anim5">
   		  <AniLink cover to="/capabilities/search-engines-optimisation" bg="#00ccd4" direction="right" duration={0.76} id="seo">Search Engines Optimisation </AniLink>
       </span>
        <span className="textReveal anim6">
		  <AniLink cover to="/capabilities/private-server-web-hosting-melbourne" bg="#00ccd4" direction="right" duration={0.76} id="web-hosting">Private Server and Web Hosting </AniLink>
        </span>
      </h2>
      <div id="specialising-pictures">
        <div id="pic-web-dev">
          <div className="picture">
            <img className="heroImage" src={'/img/services/featured-app-development.jpg'} alt="Web Development Services" />
          </div>
          <div className="shadow"></div>
        </div>
      </div>
    </section>
  )
}